<template>
  <div>
    <div class="animated fadeIn">
      <b-card body-class="p-0">
        <b-card-header>
          <b-row>
            <b-col cols="8">
              <h4 class="mt-2">
                {{ $t('payments.manageTed.refundHistory') }}
              </h4>
            </b-col>
            <!-- ESSE BOTÃO IRÁ ENTRAR NA VERSAO V1, JÁ FOI INICIADO A IMPLEMENTACAO -->
            <!-- <b-col cols="4" class="text-right mt-1">
              <b-button
                v-if="$rules.transaction.create"
                variant="success"
                @click="goTo(null)"
              >
                Nova Transferência
              </b-button>
            </b-col> -->
          </b-row>
        </b-card-header>
        <b-card-body class="p-0 wallet-table-format">
          <custom-data-table
            ref="pending_credit_table"
            :fields="fields"
            :filters="filters"
            :perPage="10"
            :query="transfer_history_driver"
            :query-variables="{ driver: $route.params.id }"
            :query-node="'getTransferHistoryDriver'"
          >
            <span slot="amount" slot-scope="props">
              {{ setPriceFormat($t('payments.labels.currency'), props.rowData.amount) }}
            </span>
            <span slot="bankAccount" slot-scope="props">
              {{ setBankAccount(props.rowData.bank_account) }}
            </span>
            <span class="pl-4" slot="created_at" slot-scope="props">
              {{ formatDateUtc(props.rowData.created_at) }}
            </span>
            <span class="pl-4" slot="approvedBy" slot-scope="props">
              {{ props.rowData.additional_data.analysed_by }}
            </span>
            <span slot="status" slot-scope="props">
              <big-badge
                :variant="setFormatStatusTed(props.rowData.status)"
                class="mt-2"
              >
                {{ setStatusTedLabel(props.rowData.status) }}
              </big-badge>
            </span>
            <span slot="url_proof" slot-scope="props">
              <b-link
                v-if="props.rowData.status === 'success'"
                :href="props.rowData.additional_data.url"
                target="_blank"
              >
                {{ $t('payments.manageTed.receipt') }} <i class="fa fa-external-link" />
              </b-link>
              <span
                v-else
                class="pl-4"
              >
                -
              </span>
            </span>
          </custom-data-table>
          <!-- <modal-new-ted
            :driverId="$route.params.id"
            v-model="modalCreditRequest"
            @create="createTed"
          /> -->
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import { formatPrice } from '@utils/numbers';
import CustomDataTable from '@components/CustomDataTable';
import TRANSFER_HISTORY_DRIVER from '@graphql/ted/queries/transfer-history-driver.gql';
import { mapGetters } from 'vuex';
// import ModalNewTed from '@components/modals/new-ted';
import { parseStatusTed, formatStatusTed} from '@utils/wallet';
import BigBadge from '@components/shared/big-badge';

export default {
  name: 'CreditRequestsByStatus',
  components: {
    CustomDataTable,
    BigBadge,
    // ModalNewTed,
    Loader: () => import('@components/shared/loader')
  },
  props: {
    fieldsAllow: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      modalData: {},
      count: 0,
      loader: false,
      modalCreditRequest: false,
      transfer_history_driver: TRANSFER_HISTORY_DRIVER,
      fields: [
        {
          name: 'amount',
          title: this.$t('payments.manageTed.table.amount'),
        },
        {
          name: 'bankAccount',
          title: this.$t('payments.manageTed.table.account')
        },
        {
          name: 'approvedBy',
          title: this.$t('payments.manageTed.table.approvedBy')
        },
        {
          name: 'status',
          title: 'Status',
          class: 'w-20 d-block text-center',
        },
        {
          name: 'created_at',
          title: this.$t('payments.manageTed.table.created_at'),
          class: 'text-center',
        },
        {
          name: 'url_proof',
          title: this.$t('payments.manageTed.receipt'),
        },
      ],
    }
  },
  computed: {
    ...mapGetters('user', ['attributes']),
    filters() {
      return {}
    },
  },
  methods: {
    formatDateUtc (date) {
      return this.$moment.utc(date).format('DD/MM/YYYY')
    },
    setBankAccount (bankAccount) {
      return `${bankAccount.bank_name} - Ag: ${bankAccount.branch} / Conta: ${bankAccount.number}`
    },
    setStatusTedLabel: parseStatusTed,
    setFormatStatusTed: formatStatusTed,
    setPriceFormat(_currency, _value) {
      return formatPrice(_currency, _value);
    },
    goTo() {
      this.modalCreditRequest = true
    },
  },
};
</script>

<style scoped>
  .custom-data-table__pagination {
    margin-bottom: 40px;
  }
</style>
